import React from "react";
import ContentSection from "../content-section";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import Achievement from "../achievement/achievement";

const apiName = "Achievements";

function Achievements() {
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    (function () {
      var elements;
      var windowHeight;
      function init() {
        elements = document.querySelectorAll(".hidden");
        windowHeight = window.innerHeight;
      }

      function checkPosition() {
        for (var i = 0; i < elements.length; i++) {
          var element = elements[i];
          var positionFromBottom = elements[i].getBoundingClientRect().bottom;

          if (positionFromBottom - windowHeight <= 0) {
            element.classList.add("fade-in-element");
            element.classList.remove("hidden");
          }
        }
      }

      window.addEventListener("scroll", checkPosition);
      window.addEventListener("resize", init);

      init();
      checkPosition();
    })();
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(apiName);
    result.sort((a, b) => a.sortOrder - b.sortOrder);

    setAchievements(result);
  };

  return (
    <ContentSection header="Latest ATC Achievements">
      <div className="container pb-5">
        <Box
          sx={{
            columnCount: { xs: 1, sm: 2, lg: 3 },
            justifyContent: "center",
            breakInside: "avoid-column",
          }}
        >
          {achievements.map((item, index) => (
            <Achievement key={index} title={item.title} iconImage={item.iconImage} description={item.description}></Achievement>
          ))}
        </Box>
      </div>
    </ContentSection>
  );
}
export default Achievements;
