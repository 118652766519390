import React, { useEffect } from "react";
import { Button, Avatar, IconButton, Divider } from "@mui/material";
import { signIn, signOut } from "../../services/auth";
import { AccountCircle, Login, Logout, Person } from "@mui/icons-material";
import { useState } from "react";
import ProfileMenu from "./profile-menu";
import { Link, useLocation } from "react-router-dom";
import { MenuSubHeader } from "../nav-bar/navbar-items";
import PropTypes from "prop-types";

function LoginButton({ user, isDrawer, handleDrawerClose }) {
    const loggedInUser = sessionStorage.getItem("user");
    const storedUser = loggedInUser ? JSON.parse(loggedInUser) : user;
    const location = useLocation(); // Hook to access the current route

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (storedUser) {
            if (storedUser.expiresAt < new Date().getTime()) {
                signOut();
            }
        }
    }, []);

    return (
        <>
            {!storedUser &&
                (isDrawer ? (
                    // only display in mobile navbar drawer
                    <MenuSubHeader title="Log In" icon={<Login />} onClick={signIn} />
                ) : (
                    // always display top left, mobile or desktop
                    <div className="d-flex align-items-center ">

                        <IconButton
                            title="Log In"
                            onClick={signIn}
                            sx={{
                                // minWidth: "100px",
                                color: "white",
                                "&:hover": {
                                    color: "#cd9e0c",
                                },
                            }}
                            size="medium"
                        ><AccountCircle sx={{
                            fontSize: {
                                xs: 20, // small size for extra-small screens
                                sm: 25, // medium size for small screens
                                md: 20, // large size for medium screens
                                lg: 25, // extra-large size for large screens
                                xl: 30  // extra-extra-large size for extra-large screens
                            }
                        }} />
                        </IconButton>
                    </div>
                ))}

            {storedUser &&
                (isDrawer ? (
                    // only display in mobile navbar drawer
                    <>
                        <MenuSubHeader
                            title="Profile"
                            icon={<Person />}
                            component={Link}
                            to="/profile"
                            onClick={handleDrawerClose}
                        />
                        <Divider />
                        <MenuSubHeader title="Log Out" icon={<Logout />} onClick={signOut} />
                        <Divider />
                    </>
                ) : (
                    // always display top left, mobile or desktop
                    <div className="d-flex align-items-center mx-xl-5 mx-0">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                        >
                            <Avatar src={storedUser.picture} sx={{ width: { lg: 50 }, height: { lg: 50 } }} />
                        </IconButton>

                        {/* Avatar Menu */}
                        <ProfileMenu anchorEl={anchorEl} open={open} handleClose={handleClose} storedUser={storedUser} />
                    </div>
                ))}
        </>
    );
}

LoginButton.propTypes = {
    user: PropTypes.object,
    isDrawer: PropTypes.bool,
    handleDrawerClose: PropTypes.func,
};

export default LoginButton;