import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader';
import { getDataFromCmsApi } from '../../api/contentful-service';
import StaticPageContainer from '../../components/static-page';
import { parseHtml } from '../../utils/utils';
import AwardCard from '../../components/awards/award-card';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

AwardsContainer.propTypes = {
    pageId: PropTypes.string.isRequired,
};

function AwardsContainer({ pageId }) {
    const [awards, setAwards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi(`webpage/${pageId}`);
        setAwards(result);
        setIsLoading(false);
    };

    return (
        isLoading ? (
            <Loader />
        ) :
            awards && (
                <StaticPageContainer title={awards.pageTitle}>
                    {awards.content && <div className='mb-3'>{parseHtml(awards.content)}</div>}
                    <Grid container spacing={2} justifyContent="center"
                        alignItems="center">

                        {awards.webpageItems &&
                            awards.webpageItems.map((award, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <AwardCard
                                        key={index}
                                        icon={award.imageUrl}
                                        title={award.title}
                                        content={parseHtml(award.description)}
                                        secondaryIcon={award.secondaryImageUrl}
                                        secondaryContent={award.secondaryDescription}
                                        link={award.link}
                                        linkText={award.linkText}
                                    />

                                </Grid>
                            ))}
                    </Grid>
                </StaticPageContainer>
            )
    );
};

export default AwardsContainer;