
import React from 'react';
import AwardsContainer from '../../components/awards/awards-container';

function TreasurerAwardsPage(props) {
    return (
        <AwardsContainer pageId={"treasurerAwards"} />
    );
};

export default TreasurerAwardsPage;