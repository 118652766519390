import React from 'react'
import DefaultLayout from '../../components/layouts/default-layout';
import GSGWidget from '../../components/gsg-widget/gsg-widget';
import { useLocation } from 'react-router';
import { govHubSubPath } from '../../utils/utils.js';

function GovHubServices() {
    const location = useLocation();
    const initialPath = location.pathname.replace('/' + govHubSubPath, '') + location.hash;

    // get page title from URL
    const regex = /^([a-zA-Z]{1,20})(-[a-zA-Z]{1,20})*$/; // matches word1-word2-...-wordn
    const words = location.pathname.split('/').filter(word => word !== "");
    
    let validParts = []; // get words before the first invalid word
    for (const word of words) {
        if (regex.test(word)) {
            validParts.push(word);
        } else {
            break;
        }
    }

    const title = validParts.length > 0
        ? validParts[validParts.length - 1].replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
        : "Tax Services";

    return (<DefaultLayout title={title} >
        <div>
            <GSGWidget
                initialPath={initialPath}
            />
        </div>
    </DefaultLayout>);
}

export default GovHubServices;
