import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import './award-card.scss';

AwardCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    secondaryIcon: PropTypes.string,
    secondaryContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    link: PropTypes.string,
    linkText: PropTypes.string,
    //side: PropTypes.oneOf(["left", "right"]),
};

function AwardCard({ title, icon, content, secondaryIcon, secondaryContent, link, linkText }) {

    const handleLink = () => {
        window.open(link, '_blank');
    };

    return (
        <Card elevation={4} className='award-card'>
            <div className='p-3 d-flex justify-content-center'>
                <CardMedia
                    component="img"
                    alt={title}
                    // height="100px"
                    image={icon}
                    className='award-image'
                    sx={{ width: 'auto', height: 65 }}
                />
            </div>
            <CardContent className='award-content'>
                <Typography gutterBottom variant="h6" component="div">
                    {title}
                </Typography>
                <Typography className='award-content-text' variant="body2" sx={{ color: 'text.secondary' }}>
                    {content}
                </Typography>
                {link &&
                    <CardActions className='award-actions'>
                        <Button size="small" onClick={handleLink}>{linkText}</Button>
                    </CardActions>
                }
            </CardContent>

        </Card >
    );
}

export default AwardCard;