import React from 'react';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import { govHubSubPath } from '../../utils/utils.js';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function ShoppingCart() {
    const navigate = useNavigate();
    const handleClick = () => {
        return navigate("/" + govHubSubPath + "/checkout");
    }
    return (
        <IconButton aria-label="shopping cart" onClick={handleClick} title="View Cart"
            sx={{
                color: "white",
                "&:hover": {
                    color: "#cd9e0c",
                }
            }}>
            <ShoppingCartIcon sx={{
                fontSize: {
                    xs: 20, // small size for extra-small screens
                    sm: 25, // medium size for small screens
                    md: 20, // large size for medium screens
                    lg: 25, // extra-large size for large screens
                    xl: 30  // extra-extra-large size for extra-large screens
                }
            }} />
        </IconButton>
    );
}

export default ShoppingCart;