import React, { useCallback, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { HierarchicalMenu, InstantSearch, RefinementList } from "react-instantsearch";
import { SearchBox } from "react-instantsearch";
import { Alert, Box, Button, ButtonGroup, Typography, useMediaQuery } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import CheckBox from "devextreme-react/check-box";
import "./document-search.scss";
import DefaultLayout from "../../components/layouts/default-layout";
import CustomFilterWidget from "../../components/filter/filter-widget";
import CustomHits from "../../components/document-search/custom-hits";
import CustomCardHits from "../../components/document-search/custom-card-hits";
import CustomFilterButton from "../../components/filter/filter-button";

const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaSearchApiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndexName = process.env.REACT_APP_ALGOLIA_REPORT_DOCUMENTS_INDEX;

const searchClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

function DocumentSearch({ ...props }) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const [searchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(isDesktop);
  const [initialUiState, setInitialUiState] = useState({
    [algoliaIndexName]: {
      query: "",
      refinementList: {},
    },
  });
  const [resultView, setResultView] = useState("table");
  const [autoExpandAll, setAutoExpandAll] = useState(false);

  const onAutoExpandAllChanged = useCallback(() => {
    setAutoExpandAll((previousAutoExpandAll) => !previousAutoExpandAll);
  }, []);

  useEffect(() => {
    const query = searchParams.get("query") || "";
    const reportTypeRefinement = searchParams.getAll("report_type");
    const divisionRefinement = searchParams.getAll("division");
    const fiscalYearRefinement = searchParams.getAll("fiscal_year");

    const refinementList = {};
    if (divisionRefinement.length > 0) {
      refinementList.division = divisionRefinement;
    }
    if (fiscalYearRefinement.length > 0) {
      refinementList.fiscal_year = fiscalYearRefinement;
    }

    const hierarchicalMenu = {};
    if (reportTypeRefinement.length > 0) {
      hierarchicalMenu["category_path.lvl0"] = reportTypeRefinement;
    }

    setInitialUiState({
      [algoliaIndexName]: {
        query,
        refinementList,
        hierarchicalMenu,
      },
    });
  }, [searchParams]);

  const handleToggleResultType = (e, value) => {
    e.preventDefault();
    setResultView(value);
  };

  const filterColumns = [
    {
      filterColumnName: "Filter By Report Type",
      refinementListComponent: (
        <HierarchicalMenu
          attributes={["category_path.lvl0", "category_path.lvl1", "category_path.lvl2"]}
          limit={10}
          showMore
          showMoreLimit={100}
          sortBy={["name:asc"]}
        />
      ),
    },
    {
      filterColumnName: "Filter By Division",
      refinementListComponent: (
        <RefinementList
          attribute="division"
          showMore
          showMoreLimit={100}
          searchable
          sortBy={["count:desc"]}
        />
      ),
    },
    {
      filterColumnName: "Filter By Fiscal Year",
      refinementListComponent: (
        <RefinementList
          attribute="fiscal_year"
          limit={10}
          showMore
          showMoreLimit={100}
          searchable
          sortBy={["name:desc"]}
        />
      ),
    },
    {
      filterColumnName: "Filter By Quarter",
      refinementListComponent: <RefinementList attribute="quarter" sortBy={["name:asc"]} />,
    },
  ];

  return (
    <DefaultLayout title="Document Search" description="Welcome to San Bernardino County's ">
      <div className="container mt-5 mb-5">
        <Alert severity="info" icon={false} className="mb-4">
          <Typography variant="h4">Welcome to ATC&apos;s Report & Document Repository.</Typography>
          <p>
            Here you can search reports and documents by name, report type, division, and more to quickly find
            what you need.
          </p>
        </Alert>

        <InstantSearch
          searchClient={searchClient}
          indexName={algoliaIndexName}
          initialUiState={initialUiState}
          key={JSON.stringify(initialUiState)}
          // routing={true}
        >
          {/* Search Bar & Tools */}
          <div className="d-flex">
            <SearchBox placeholder="Search Documents" searchAsYouType className="w-100 me-2" />
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button onClick={(e) => handleToggleResultType(e, "grid")}>
                <GridViewIcon />
              </Button>
              <Button onClick={(e) => handleToggleResultType(e, "table")}>
                <TableRowsIcon />
              </Button>
            </ButtonGroup>
          </div>

          {/* Buttons */}
          <div className="d-flex justify-content-between column-gap-2 pt-4 pb-2">
            <CustomFilterButton isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} {...props} />
            <CheckBox
              text="Collapse All"
              id="collapse-all-checkbox"
              value={!autoExpandAll}
              onValueChanged={onAutoExpandAllChanged}
            />
          </div>

          {/* Filter Widget & Results */}
          <div className={`d-flex ${isFilterOpen ? "gap-3" : "gap-0"}`}>
            <CustomFilterWidget
              filterColumns={filterColumns}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              isDesktop={isDesktop}
              {...props}
            />

            <Box sx={{ flexBasis: 0, flexGrow: 1, transition: "width 0.3s ease", overflow: "hidden" }}>
              {resultView === "table" ? (
                <CustomHits
                  algoliaIndexName={algoliaIndexName}
                  searchClient={searchClient}
                  autoExpandAll={autoExpandAll}
                  isFilterOpen={isFilterOpen}
                  {...props}
                />
              ) : (
                <CustomCardHits {...props} />
              )}
            </Box>
          </div>
        </InstantSearch>
      </div>
    </DefaultLayout>
  );
}

DocumentSearch.propTypes = {
  initialState: PropTypes.object,
};

export default DocumentSearch;
