import React from 'react';
import PropTypes from 'prop-types';
import { one } from 'devextreme/events';

Achievement.propTypes = {
    title: PropTypes.string.isRequired,
    iconImage: PropTypes.string,
    iconUrl: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

function Achievement({ title, iconImage, iconUrl, description }) {
    return (
        <div className="four-part-border hidden" style={{ breakInside: "avoid-column" }}>
            <p className="w3-border shadow-lg p-4 p-md-5 bg-body rounded">
                <div>
                    <img src={iconImage ? iconImage : `https:${iconUrl}`} className="achieve-icon  mx-2" alt=""></img>
                    <b>{title}</b>
                    {" "}{description}
                </div>
            </p>
        </div>
    );
}

export default Achievement;