import { Box, Collapse, SwipeableDrawer, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import NavbarDrawerHeader from "../nav-bar/navbar-drawer-header";
import { FilterList } from "@mui/icons-material";

const filterBackgroundColor = "#eef4fa";

const CustomFilterWidgetContent = ({ filterColumns, isDesktop }) => {
  return (
    <Box
      className={`d-grid row-gap-4 column-gap-5 py-3 ${isDesktop ? "px-3" : "px-4"}`}
      sx={{ gridAutoRows: "auto" }}
    >
      {filterColumns &&
        filterColumns.map((column, index) => (
          <div key={index} className="pb-2 w-100">
            <Typography variant="overline" component="h1" gutterBottom>
              {column.filterColumnName}
            </Typography>
            {column.refinementListComponent}
          </div>
        ))}
    </Box>
  );
};

CustomFilterWidgetContent.propTypes = {
  filterColumns: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool,
};

const CustomFilterWidget = ({ filterColumns, isFilterOpen, setIsFilterOpen = null, isDesktop }) => {
  return isDesktop ? (
    <Collapse
      orientation="horizontal"
      in={isFilterOpen}
      sx={{
        backgroundColor: filterBackgroundColor,
        marginBottom: isFilterOpen ? 1 : 0,
        height: "fit-content",
        "& .MuiCollapse-wrapperInner": { width: "300px !important" },
      }}
    >
      <CustomFilterWidgetContent filterColumns={filterColumns} isDesktop={isDesktop} />
    </Collapse>
  ) : (
    <SwipeableDrawer
      anchor="left"
      open={isFilterOpen}
      onClose={() => setIsFilterOpen(false)}
      onOpen={() => setIsFilterOpen(true)}
      sx={{ zIndex: 10000 }}
      PaperProps={{
        sx: {
          backgroundColor: filterBackgroundColor,
          maxWidth: "350px",
          width: "100%",
        },
      }}
    >
      <NavbarDrawerHeader handleDrawerClose={() => setIsFilterOpen(false)}>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 10, color: "white", gap: 10.3 }}>
          <FilterList />
          <Typography variant="button" fontSize="16px">
            Filter
          </Typography>
        </div>
      </NavbarDrawerHeader>
      <CustomFilterWidgetContent filterColumns={filterColumns} isDesktop={isDesktop} />
    </SwipeableDrawer>
  );
};

CustomFilterWidget.propTypes = {
  filterColumns: PropTypes.array.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  setIsFilterOpen: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
};

export default CustomFilterWidget;
