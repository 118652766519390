import React from "react";
import { Link as RouterLink } from "react-router-dom/dist";
import { Box, Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";

const MenuSubHeader = ({ title, icon, ...props }) => (
  <Box sx={{ fontWeight: 700, padding: ".25rem" }}>
    <Button
      variant="text"
      sx={{ width: "100%", justifyContent: "flex-start" }}
      {...props}
      component={RouterLink}
    >
      <ListItemIcon sx={{ minWidth: "25px", color: "#1c6889", fontSize: "150%" }}> {icon} </ListItemIcon>
      <Typography sx={{ ml: ".75rem", color: "#1c6889" }}>{title}</Typography>
    </Button>
  </Box>
);

const CustomMenuItem = ({ children, ...props }) => (
  <MenuItem className="ps-4" component={RouterLink} {...props} >
    <Box className="ps-4" sx={{ textTransform: "uppercase" }}>
      {children}
    </Box>
  </MenuItem>
);

const MenuTemplate = ({ id, isOpen, handleOpenMenu, handleCloseMenu, itemsList, bottomAnchor }) => {
  return (
    bottomAnchor && (
      <Menu
        id={id}
        MenuListProps={{
          "aria-labelledby": { id },
          onMouseEnter: handleOpenMenu,
          onMouseLeave: handleCloseMenu,
        }}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: bottomAnchor,
          left: id === "online-services-menu" ? window.innerWidth / 3 : window.innerWidth / 2,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        open={isOpen}
        sx={{
          "& .MuiPaper-root": { borderRadius: 2 },
          zIndex: 1019 /* z-index of sticky navbar is 1020, but Menu is originally 1300 */,
        }}
      >
        <Box
          sx={{
            columnCount:
              id === "divisions-menu"
                ? { xs: 1, md: 2, xl: 3 }
                : id === "online-services-menu"
                  ? { xs: 1 }
                  : { xs: 1, md: 2 },
            columnGap: "7em",
            padding: "0.5rem 3rem",
            justifyContent: "center",
          }}
        >
          {itemsList.map((list, i) => (
            <Box key={i} sx={{ breakInside: "avoid-column" }}>
              {list.subheader && (
                <MenuSubHeader
                  title={list.subheader}
                  to={list.subheaderLink}
                  icon={list.icon}
                  onClick={handleCloseMenu}
                />
              )}
              {list.items.map((item, j) => (
                <CustomMenuItem
                  key={j}
                  to={item.itemLink}
                  target={item.itemTarget}
                  rel={"noopener noreferrer"}
                  onClick={handleCloseMenu}
                >
                  {item.item}
                </CustomMenuItem>
              ))}
              <Divider />
            </Box>
          ))}
        </Box>
      </Menu>
    )
  );
};

MenuSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

CustomMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
};

MenuTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  handleOpenMenu: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      subheader: PropTypes.string,
      subheaderLink: PropTypes.string,
      icon: PropTypes.node,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          itemLink: PropTypes.string.isRequired,
          item: PropTypes.node.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  bottomAnchor: PropTypes.number,
};

export default MenuTemplate;
