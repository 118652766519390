import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, useHits, RefinementList, useSearchBox } from "react-instantsearch";
import StaticPageContainer from "../../components/static-page";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { parseHtml } from "../../utils/utils";
import CustomFilterWidget from "../../components/filter/filter-widget";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import PropTypes from "prop-types";
import CopyHashLinkButton from "../../components/copy-hash-link-button";
import "./faq.scss";
import CustomFilterButton from "../../components/filter/filter-button";

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const primaryTextColor = "#003366";
const backgroundColorLight = "#f9f9fa";

const CustomHighlight = ({ attribute, hit }) => {
  const highlightValue = hit._highlightResult[attribute].value;
  const parsedHTML = parseHtml(highlightValue);

  return <>{parsedHTML}</>;
};

CustomHighlight.propTypes = {
  attribute: PropTypes.string,
  hit: PropTypes.object,
};

const CustomAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    border: "none",
    "&::before": {
      display: "none",
    },
  };
});

const CustomHits = ({ setHits, setIsQueryEmpty, ...props }) => {
  const { results } = useHits(props);
  const { hits } = results;

  const { query } = useSearchBox();
  const isQueryEmpty = !query || query.trim() === "";

  useEffect(() => {
    if (!results) return null;

    setHits(hits);
    setIsQueryEmpty(isQueryEmpty);
  }, [hits, isQueryEmpty, setHits, setIsQueryEmpty]);

  if (!hits.length) {
    return <Typography variant="h6">No results found</Typography>;
  }

  const groupedByFaqType = hits.reduce((acc, { typeName, typeDescription, ...rest }) => {
    if (!acc[typeName]) {
      acc[typeName] = {
        description: typeDescription,
        items: [],
      };
    }
    acc[typeName].items.push({ typeName, typeDescription, ...rest });
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedByFaqType).map((type) => {
        const formattedId = type.trim().replace(/\s+/g, "-");

        return (
          <div id={formattedId} key={type} className="faq-text pb-5 mb-3">
            <div className="d-flex align-items-center" style={{ columnGap: "0.7rem" }}>
              <h4 style={{ color: primaryTextColor }}>{type}</h4>
              <CopyHashLinkButton elementId={formattedId} iconSize={18} />
            </div>
            <div className="fs-6 mb-2">{parseHtml(groupedByFaqType[type].description)}</div>

            {groupedByFaqType[type].items.map((hit, i) => (
              <div id={hit.objectID} key={i} className="py-1">
                <CustomAccordion
                  defaultExpanded={hit.objectID === window.location.hash.replace("#", "")}
                  style={{ backgroundColor: backgroundColorLight, borderRadius: 10 }}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      window.history.replaceState(null, "", `#${hit.objectID}`);
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div
                      className="d-flex m-0"
                      style={{ color: primaryTextColor, columnGap: "0.45rem", fontSize: "18px" }}
                    >
                      <CustomHighlight attribute="question" hit={hit} />
                      {/* <CopyHashLinkButton elementId={hit.objectID} iconSize={15} /> */}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="d-flex flex-column pt-0" style={{ fontSize: "16px" }}>
                    <CustomHighlight attribute="answer" hit={hit} />
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

CustomHits.propTypes = {
  setHits: PropTypes.func,
  setIsQueryEmpty: PropTypes.func,
};

const filterColumns = [
  {
    filterColumnName: "Filter By Topic",
    refinementListComponent: <RefinementList attribute="typeName" />,
  },
  {
    filterColumnName: "Filter By Division",
    refinementListComponent: <RefinementList attribute="divisionNames" />,
  },
];

function FAQ(props) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const [isFilterOpen, setIsFilterOpen] = useState(isDesktop);
  const [hits, setHits] = useState([]);
  const [isQueryEmpty, setIsQueryEmpty] = useState(true);

  useEffect(() => {
    if (!isQueryEmpty) {
      return;
    }

    // Initial scroll on page load
    if (window.location.hash && hits.length > 0) {
      scrollToQuestion();
    }

    // Listen to hash changes
    window.addEventListener("hashchange", scrollToQuestion);
    return () => {
      window.removeEventListener("hashchange", scrollToQuestion);
    };
  }, [hits, isQueryEmpty]);

  const scrollToQuestion = () => {
    const elementId = window.location.hash.replace("#", "");
    const navbarHeight = document.querySelector("header")?.offsetHeight || 0;

    // Find the element by ID and scroll to it
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - navbarHeight - 20,
        behavior: "smooth",
      });
    }
  };

  return (
    <StaticPageContainer title={"Frequently Asked Questions"}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_FAQ_INDEX}
        routing={{
          stateMapping: singleIndex(`${process.env.REACT_APP_ALGOLIA_FAQ_INDEX}`),
        }}
      >
        {/* Search */}
        <div className="faq-searchbox d-flex justify-content-center py-2">
          <SearchBox placeholder="Search FAQ" searchAsYouType />
        </div>

        {/* Filter */}
        <div className="pt-4 pb-3">
          <CustomFilterButton isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} {...props} />
        </div>

        {/* Filter Widget & Results */}
        <div className={`d-flex ${isFilterOpen ? "gap-4" : "gap-0"}`}>
          <CustomFilterWidget
            filterColumns={filterColumns}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            isDesktop={isDesktop}
            {...props}
          />

          <Box sx={{ paddingTop: 2, flexBasis: 0, flexGrow: 1, transition: "width 0.3s ease", overflow: "hidden" }}>
            <CustomHits setHits={setHits} setIsQueryEmpty={setIsQueryEmpty} {...props} />
          </Box>
        </div>
      </InstantSearch>
    </StaticPageContainer>
  );
}

FAQ.propTypes = {
  resultsState: PropTypes.object,
};

export default FAQ;
