import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import PropTypes from "prop-types";

const DrawerHeaderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  backgroundColor: "#003366",
}));

const NavbarDrawerHeader = ({ children, handleDrawerClose, ...other }) => {
  return (
    <DrawerHeaderStyle className="nav-logo-wrapper justify-content-between position-sticky top-0 z-1" onClick={handleDrawerClose}>
      {children}
      <IconButton onClick={handleDrawerClose} sx={{ mx: 1 }}>
        <Close style={{ color: "white" }} />
      </IconButton>
    </DrawerHeaderStyle>
  );
};

NavbarDrawerHeader.propTypes = {
  children: PropTypes.node,
  handleDrawerClose: PropTypes.func,
};

export default NavbarDrawerHeader;
