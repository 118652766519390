import React from 'react';
import LoginButtonContainer from '../../containers/login-button-container';
import ShoppingCart from './shopping-cart';
import { GTranslate } from '../../services/g-translate/g-translate';

function IconNavContainer(props) {
    return (
        <div className='d-flex align-items-center'>
            <GTranslate />
            <LoginButtonContainer isDrawer={false} />
            <ShoppingCart></ShoppingCart>
        </div>
    );
}

export default IconNavContainer;